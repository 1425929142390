body {
  margin: 0;
  font-family: 'Open Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.introjs-tooltip {
  color: black !important;
  border-radius: 8px !important;
}

.introjs-button {
  font-size: 16px;
  font-weight: bold;
  text-shadow: none;
  transform: none;
  line-height: 1.5;
}

.introjs-progress {
  background-color: #f0f0f0;
}

.introjs-progressbar {
  background-color: #FF6B5E;
}

